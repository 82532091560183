import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "glossary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#glossary",
        "aria-label": "glossary permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Glossary`}</h1>
    <h3 {...{
      "id": "funder-codes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#funder-codes",
        "aria-label": "funder codes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Funder codes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hicaps`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hicaps`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`medicare`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dva`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DVA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`patient-funded`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Paid for by the Patient (Out of pocket)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "health-funds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#health-funds",
        "aria-label": "health funds permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Health funds`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Health Fund`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AAMI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AAMI Health Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ACA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ACA Health Benefits Fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AHB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Defence Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AHM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AHM`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AMA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Doctors' Health Fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`APIA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`APIA Health Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AUF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Australian Unity`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BUP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bupa`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CBH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CBHS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CDH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CDH`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CPS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CUA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ESH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Emergency Services Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FHI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Frank Health Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GMF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GMF Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GMH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GMHBA Limited`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GUH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Grand United Corporate Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HBF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HBF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HCF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HCF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HCI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Health Care Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HEA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`health.com.au`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HIF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HIF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HPI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Health Partners`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LHM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Peoplecare`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LHS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Latrobe Health Services`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MBP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medibank Private Limited`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MDC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medicare`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MDH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mildura Health Fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MYO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`myOwn Health Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NHB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navy Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NIB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NIB`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NMW`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nurses & Midwives Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OMF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`onemedifund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PHF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Police Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PWA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phoenix Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QCH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Queensland Country Health Fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QTA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Qantas Assure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QTU`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TUH`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RBH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reserve Bank Health Society`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RTE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Railway and Transport Health Fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`STL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`St. Lukes Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SUN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suncorp Health Insurance`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TFH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Teachers Health Fund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TFS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transport Health`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WFD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Westfund Limited`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "terms--meanings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#terms--meanings",
        "aria-label": "terms  meanings permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Terms & meanings`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Term`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Meaning`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Business`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An entity registered with HICAPS, Medicare or Medipass, which can have one or more practices. For example, the “Pressure Point Physio” business could have two practices: one in Melbourne and one in Sydney. Each business can have one one or more registered practitioners.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A claim describes the costs and benefits a member receives for a health service.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A transaction containing outlining all costs, claims, and payments relating to a health service.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Member`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A person registered with Medipass who may claim health benefits from sources such as Private Health Insurance and Medicare. Alternative terms for a member could include “patient” or “customer”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Modality`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The category of service that a practitioner offers. Examples are physiotherapy, dental etc.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Payment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A payment made from a payment source such as a credit card.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Practice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A registered, physical location where one more registered practitioners provide health services. Using the example in Business, the Melbourne and Sydney Pressure Point locations would each be different practices.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Practitioner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A health service provider registered with HICAPS, Medicare or Medipass. A practitioner must have a valid HICAPS provider number to register with Medipass.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Non claimable item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Something that is paid for as part of a health service, but is not claimable. This could be physical objects such as massage balls or contact lens solution.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Staff`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A general term for employees of health care practices who have accounts with Medipass.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      